<template>
  <v-app dark="dark">
      <slot />
      <v-footer>
        <v-flex class="text-xs-center">© 2021. Desenvolvido por DViT Solutions.</v-flex>
      </v-footer>
  </v-app>
</template>

<script>
export default {
};
</script>
import firebase from 'firebase'
import store from '@/store'
import { v4 as uuidv4 } from 'uuid';


const firebaseConfig = {
    apiKey: "AIzaSyDf2Tj_C_dD76ic0G5xpoBpsbqsTYB8rk4",
    authDomain: "festit-c0636.firebaseapp.com",
    projectId: "festit-c0636",
    storageBucket: "festit-c0636.appspot.com",
    messagingSenderId: "133375235827",
    appId: "1:133375235827:web:83d0bcdde030eb6b17c986",
    measurementId: "G-3QSV8XQRR0"
};

var environment = "prod";
//var environment = "dev";


const database = firebase.initializeApp(firebaseConfig)

function generateId() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }
  

database.signIn = async(email, password) => {
    try {
        await firebase.auth().signInWithEmailAndPassword(email, password)
        var data = null
        await database.firestore().collection('environments').doc(environment).collection('usuarios').doc(firebase.auth().currentUser.uid).get()
            .then(function(doc) {
                if (doc.exists) {
                    data = doc.data()
                    data["id"] = doc.id
                } else {
                    data = null
                }
            })
        if (data == null) {
            return {
                message: "Usuário não encontrado, verifique a conexão e tente novamente!"
            }
        }
        if(data.tipo == "ADMIN") {
            store.commit('setUsuario', data)
            return true
        } else {
            await firebase.auth().signOut()
            return {
                message: "Você não tem permissão!"
            }
        }
        
        
    } catch (error) {
        // console.log(error)
        return {
            message: "Operação falhou, verifique a conexão e tente novamente!"
        }
    }
}

database.signOut = async() => {
    try {
        await firebase.auth().signOut()

        store.commit('setUsuario', null)

        return true
    } catch (error) {
        return error
    }
}

database.recoveryPassword = async(email) => {
    try {
        await firebase.auth().sendPasswordResetEmail(email)
        return true
    } catch (error) {
        return false
    }
}

//=========================================================================

database.getStatus = async() => {
    var usuarios_cadastrados = await database.getAllUsuarios();
    var inspiracoes_cadastradas = await database.getAllInspiracoes();
    var fornecedores = await database.getAllFornecedores();
    var pendentes = await database.getAllFornecedoresPendente();
    var avaliacoes = await database.getAllAvaliacoes();
    var fornecedores_visiveis = fornecedores.filter(function(e) {
        return e.tag !== ""
    })
    var fornecedores_ocultos = fornecedores.filter(function(e) {
        return e.tag === ""
    })
    return {
        usuarios: usuarios_cadastrados.length,
        inspiracoes: inspiracoes_cadastradas.length,
        fornecedores_visiveis: fornecedores_visiveis.length,
        fornecedores_ocultos: fornecedores_ocultos.length,
        avaliacoes_pendentes: avaliacoes.length,
        fornecedores_pendentes: pendentes.length
    };
}

database.getAllCategorias = async() => {
    var categorias = []
    await database.firestore().collection('environments').doc(environment).get()
        .then(function(documentSnapshot) {
            categorias = documentSnapshot.data().categorias;
        })
    categorias.sort((a, b) => a.nome > b.nome ? 1 : a.nome < b.nome ? -1 : 0)
    store.commit('setCategorias', categorias)
    return categorias
}

database.getAllUsuarios = async() => {
    var usuarios = []
    await database.firestore().collection('environments').doc(environment).collection('usuarios').where("tipo", "==", "USUARIO").get()
        .then(function(querySnapshot) {
            querySnapshot.forEach(function(doc) {
                var obj = doc.data()
                obj["id"] = doc.id
                usuarios.push(obj)
            })
        })
        usuarios.sort((a, b) => a.data_criacao < b.data_criacao ? 1 : a.data_criacao > b.data_criacao ? -1 : 0)
    return usuarios
}

database.getUsuarioById = async(id) => {
    var usuario = null
    await database.firestore().collection('environments').doc(environment).collection('usuarios').doc(id).get()
        .then(function(documentSnapshot) {
            var obj = documentSnapshot.data()
            obj["id"] = documentSnapshot.id
            usuario = obj;
        })
    return usuario
}

database.getAllFornecedores = async() => {
    var ordem_fornecedores = []
    await database.firestore().collection('environments').doc(environment).get()
        .then(function(doc) {
            if (doc.exists) {
                ordem_fornecedores = doc.data().ordem_fornecedores
            } else {
                ordem_fornecedores = null
            }
        })
    var fornecedores = []
    await database.firestore().collection('environments').doc(environment).collection('fornecedores').where("status", "==", "APROVADO").get()
        .then(function(querySnapshot) {
            querySnapshot.forEach(function(doc) {
                var obj = doc.data()
                obj["id"] = doc.id
                fornecedores.push(obj)
            })
        })
    //fornecedores.sort((a, b) => a.nome_empresa > b.nome_empresa ? 1 : a.nome_empresa < b.nome_empresa ? -1 : 0)

    var fornecedores_ordered = []

    ordem_fornecedores.forEach(function(posicao, i) {
        fornecedores_ordered.push(fornecedores.find(item => item.id === posicao))
    })

    return fornecedores_ordered
}

database.getFornecedorById = async(id) => {
    var fornecedor = null
    await database.firestore().collection('environments').doc(environment).collection('fornecedores').doc(id).get()
        .then(function(documentSnapshot) {
            var obj = documentSnapshot.data()
            obj["id"] = documentSnapshot.id
            fornecedor = obj;
        })
    return fornecedor
}

database.getAllFornecedoresPendente = async() => {
    var fornecedoresPendentes = []
    await database.firestore().collection('environments').doc(environment).collection('fornecedores').where("status", "==", "PENDENTE").get()
        .then(function(querySnapshot) {
            querySnapshot.forEach(function(doc) {
                var obj = doc.data()
                obj["id"] = doc.id
                fornecedoresPendentes.push(obj)
            })
        })

        fornecedoresPendentes.sort((a, b) => a.data_criacao > b.data_criacao ? 1 : a.data_criacao < b.data_criacao ? -1 : 0)
    return fornecedoresPendentes
}

database.getAllFornecedoresReprovado = async() => {
    var fornecedoresReprovados = []
    await database.firestore().collection('environments').doc(environment).collection('fornecedores').where("status", "==", "REPROVADO").get()
        .then(function(querySnapshot) {
            querySnapshot.forEach(function(doc) {
                var obj = doc.data()
                obj["id"] = doc.id
                fornecedoresReprovados.push(obj)
            })
        })

        fornecedoresReprovados.sort((a, b) => a.data_criacao > b.data_criacao ? 1 : a.data_criacao < b.data_criacao ? -1 : 0)
    return fornecedoresReprovados
}

database.getAllInspiracoes = async() => {
    var ordem_inspiracoes = []
    await database.firestore().collection('environments').doc(environment).get()
        .then(function(doc) {
            if (doc.exists) {
                ordem_inspiracoes = doc.data().ordem_inspiracoes
            } else {
                ordem_inspiracoes = null
            }
        })
    var inspiracoes = []
    await database.firestore().collection('environments').doc(environment).collection('inspiracoes').get()
        .then(function(querySnapshot) {
            querySnapshot.forEach(function(doc) {
                var obj = doc.data()
                obj["id"] = doc.id
                inspiracoes.push(obj)
            })
        })
    var inspiracoes_ordered = []

    ordem_inspiracoes.forEach(function(posicao, i) {
        inspiracoes_ordered.push(inspiracoes.find(item => item.id === posicao))
    })
    return inspiracoes_ordered
}

database.getInspiracaoById = async(id) => {
    var inspiracao = null
    await database.firestore().collection('environments').doc(environment).collection('inspiracoes').doc(id).get()
        .then(function(documentSnapshot) {
            var obj = documentSnapshot.data()
            obj["id"] = documentSnapshot.id
            inspiracao = obj;
        })
    return inspiracao
}

database.getAllCategoriasConvites = async () => {
    const docs = await database.firestore().collection("environments").doc(environment).collection("categoria_convite").get();
    const categorias = await Promise.all(docs.docs.map((el) => {
        return {
            id: el.id,
            ...el.data()
        }
    }));
    await categorias.sort((a, b) => a.ordem - b.ordem);
    return categorias;
}

database.getCategoriaConviteById = async (id) => {
    const doc = await database.firestore().collection("environments").doc(environment).collection("categoria_convite").doc(id);
    const docData = (await doc.get()).data();
    console.log(docData);
    return {
        id: doc.id,
        ...docData
    }
}

database.saveCategoria = async (categoria, id) => {
    if (!id) await database.firestore().collection('environments').doc(environment).collection("categoria_convite").add(categoria);
    else {
        delete categoria.id;
        await database.firestore().collection('environments').doc(environment).collection("categoria_convite").doc(id).update(categoria);
    }
}


database.deleteCategoria = async (categoriaId) => {
    const categorias = await database.getAllCategoriasConvites();
    const categoria= categorias.find((el) => {
        return el.id === categoriaId;
    });
    try {
        database.firestore().collection('environments').doc(environment).collection("categoria_convite").doc(categoria.id).delete();
    } catch (error) {
       return "Verifique sua conexão ou entre em contato com o suporte caso o problema persista."
    }
    
    return true
}

database.deleteTemaById = async (temaId) => {
    try {
        await database.firestore().collection('environments').doc(environment).collection('temas').doc(temaId).delete();   
    } catch(error) {
        return "Verifique sua conexão ou entre em contato com o suporte caso o problema persista."
    }
    return true
}

database.deleteConviteById = async (conviteId) => {
    try {
        await database.firestore().collection('environments').doc(environment).collection('convites').doc(conviteId).delete();   
    } catch(error) {
        return "Verifique sua conexão ou entre em contato com o suporte caso o problema persista."
    }
    return true
}

database.getAllTemas = async () => {
    const temas = await database.firestore().collection('environments').doc(environment).collection('temas').get();
    const retorno = temas.docs.map((doc) => {
        return {
            id: doc.id,
            ...doc.data()
        }
    });
    await retorno.sort((a, b) => a.ordem - b.ordem);
    return retorno;
}

database.getTemaById = async (id) => {
    const doc = await database.firestore().collection('environments').doc(environment).collection('temas').doc(id);
    const docData = (await doc.get()).data();
    return {
        id: doc.id,
        ...docData
    }
}

database.addTema = async (tema, id) => {
    if (!id) await database.firestore().collection('environments').doc(environment).collection('temas').add(tema);
    else {
        delete tema.id;
        await database.firestore().collection('environments').doc(environment).collection('temas').doc(id).update(tema);
    }
}

database.addConvite = async (convite, id) => {
    if (!id) await database.firestore().collection('environments').doc(environment).collection('convites').add(convite);
    else {
        delete convite.id;
        await database.firestore().collection('environments').doc(environment).collection('convites').doc(id).update(convite);
}
    }

database.getAllConvites = async () => {
    const temas = await database.firestore().collection('environments').doc(environment).collection('convites').get();
    const retorno = temas.docs.map((doc) => {
        return {
            id: doc.id,
            ...doc.data()
        }
    });
    await retorno.sort((a, b) => a.ordem - b.ordem);
    return retorno;
}

database.getConviteById = async (id) => {
    const doc = await database.firestore().collection('environments').doc(environment).collection('convites').doc(id);
    const docData = (await doc.get()).data();
    return {
        id: doc.id,
        ...docData
    }
}

database.getAllAvaliacoes = async() => {
    var avaliacoes = []
    await database.firestore().collection('environments').doc(environment).collection('avaliacoes').where("status", "==", "PENDENTE").get()
        .then(function(querySnapshot) {
            querySnapshot.forEach(function(doc) {
                var obj = doc.data()
                obj["id"] = doc.id
                avaliacoes.push(obj)
            })
        })
        avaliacoes.sort((a, b) => a.data > b.data ? 1 : a.data < b.data ? -1 : 0)
    return avaliacoes
}

//=========================================================================

database.addInspiracao = async(id, inspiracao) => {
    try {
        var ordem = []
        await database.firestore().collection('environments').doc(environment).get()
            .then(function(doc) {
                if (doc.exists) {
                    ordem = doc.data().ordem_inspiracoes
                } else {
                    ordem = null
                }
            })
        if (ordem === null) {
            return false;
        }
        ordem.push(id);
        await database.firestore().collection('environments').doc(environment).collection("inspiracoes").doc(id).set(
            inspiracao, {merge: true}
        );
        var ref = await database.firestore().collection('environments').doc(environment);
        await ref.set({
            ordem_inspiracoes: ordem
        }, { merge: true });
        return true;
    } catch (error) {
        console.log(error);
        return false
    }
}

//=========================================================================

database.updateFornecedorStatus = async(id, status) => {
    try {
        var ordem = []
        await database.firestore().collection('environments').doc(environment).get()
            .then(function(doc) {
                if (doc.exists) {
                    ordem = doc.data().ordem_fornecedores
                } else {
                    ordem = null
                }
            })
        if (ordem === null) {
                return false;
        }
        if(status === "APROVADO") {
            ordem.push(id);

            var ref = await database.firestore().collection('environments').doc(environment);
            await ref.set({
                ordem_fornecedores: ordem
            }, { merge: true });

        } else if (status === "REPROVADO"){
            for( var i = 0; i < ordem.length; i++){ 
                if ( ordem[i] === id) { 
                    ordem.splice(i, 1); 
                }
            }

            var ref = await database.firestore().collection('environments').doc(environment);
            await ref.set({
                ordem_fornecedores: ordem
            }, { merge: true });
        }
        var ref = await database.firestore().collection('environments').doc(environment).collection('fornecedores').doc(id);
        return await ref.set({
            status: status
        }, { merge: true }).then(function() {
            return true
        }).catch(function(error) {
            return false
        });
    } catch (error) {
        return false
    }
}

database.updateFornecedoresOrdem = async(ordem) => {
    try {
        var ref = await database.firestore().collection('environments').doc(environment);
        return await ref.set({
            ordem_fornecedores: ordem
        }, { merge: true }).then(function() {
            return true
        }).catch(function(error) {
            return false
        });

    } catch (error) {
        return false
    }
}

database.updateFornecedor = async(data) => {
    try {
        var ref = await database.firestore().collection('environments').doc(environment).collection('fornecedores').doc(data.id);
        return await ref.set({
            categoria_id: data.categoria_id,
            descricao: data.descricao,
            mensagem: data.mensagem,
            nome_empresa: data.nome_empresa,
            tag: data.tag,
            telefone: data.telefone,
            uf: data.uf,
            whatsapp: data.whatsapp,
            localizacao: data.localizacao,
            pacotes: data.pacotes,
            promocoes: data.promocoes,
            fotos: data.fotos,
            color_tag: data.color_tag
        }, { merge: true }).then(function() {
            return true
        }).catch(function(error) {
            return false
        });

    } catch (error) {
        return false
    }
}

database.updateInspiracao = async(data) => {
    try {
        var ref = await database.firestore().collection('environments').doc(environment).collection('inspiracoes').doc(data.id);
        return await ref.set({
            titulo: data.titulo,
            mensagem: data.mensagem,
            conteudo: data.conteudo,
            tags: data.tags,
            telefone: data.telefone,
            categoria_id: data.categoria_id,
            banners: data.banners
        }, { merge: true }).then(function() {
            return true
        }).catch(function(error) {
            return false
        });

    } catch (error) {
        return false
    }
}

database.updateInspiracoesOrdem = async(ordem) => {
    try {
        var ref = await database.firestore().collection('environments').doc(environment);
        return await ref.set({
            ordem_inspiracoes: ordem
        }, { merge: true }).then(function() {
            return true
        }).catch(function(error) {
            
            return false
        });

    } catch (error) {
        return false
    }
}

database.updateConvitesOrdem = async(ordem) => {
    try {
        await Promise.all(ordem.map(async (item) => {
           await database.firestore().collection('environments').doc(environment).collection('convites').doc(item.id).update(item);
        }))

    } catch (error) {
        console.log(error);
        return false
    }
    return true;
}


database.updateCategoriaConviteOrdem = async(ordem) => {
    try {
        await Promise.all(ordem.map(async (item) => {
           await database.firestore().collection('environments').doc(environment).collection('categoria_convite').doc(item.id).update(item);
        }))

    } catch (error) {
        console.log(error);
        return false
    }
    return true;
}


database.updateTemaOrdem = async(ordem) => {
    try {
        await Promise.all(ordem.map(async (item) => {
           await database.firestore().collection('environments').doc(environment).collection('temas').doc(item.id).update(item);
        }))

    } catch (error) {
        console.log(error);
        return false
    }
    return true;
}


database.updateAvaliacaoStatus = async(id, status) => {
    try {
        var ref = await database.firestore().collection('environments').doc(environment).collection('avaliacoes').doc(id);
        return await ref.set({
            status: status
        }, { merge: true }).then(function() {
            return true
        }).catch(function(error) {
            return false
        });

    } catch (error) {
        return false
    }
}

//=========================================================================

database.deleteFornecedorById = async(id) => {
    try {
        await database.firestore().collection('environments').doc(environment).collection('fornecedores').doc(id).delete();
        return true
    } catch (error) {
        return "Verifique sua conexão ou entre em contato com o suporte caso o problema persista."
    }
}

database.deleteFornecedorOrdemId = async(id) => {
    try {
        await database.firestore().collection('environments').doc(environment).collection('fornecedores').doc(id).delete();
        return true
    } catch (error) {
        return "Verifique sua conexão ou entre em contato com o suporte caso o problema persista."
    }
}

database.deleteAvaliacaoById = async(id) => {
    try {
        await database.firestore().collection('environments').doc(environment).collection('avaliacoes').doc(id).delete();
        return true
    } catch (error) {
        return "Verifique sua conexão ou entre em contato com o suporte caso o problema persista."
    }
}

database.deleteInspiracaoById = async(id) => {
    try {
        var ordem = []
        await database.firestore().collection('environments').doc(environment).get()
            .then(function(doc) {
                if (doc.exists) {
                    ordem = doc.data().ordem_inspiracoes
                } else {
                    ordem = null
                }
            })
        if (ordem === null) {
                return false;
        }
        for( var i = 0; i < ordem.length; i++){ 
            if ( ordem[i] === id) { 
                ordem.splice(i, 1); 
            }
        }
        var ref = await database.firestore().collection('environments').doc(environment);
        await ref.set({
            ordem_inspiracoes: ordem
        }, { merge: true });
        await database.firestore().collection('environments').doc(environment).collection('inspiracoes').doc(id).delete();
        
        return true
    } catch (error) {
        return "Verifique sua conexão ou entre em contato com o suporte caso o problema persista."
    }
}


//=========================================================================

database.uploadImagemFornecedor = async(file, type, id, name) => {
    var storageRef = firebase.storage().ref();
    var path = environment.concat("/fornecedores/").concat(id).concat("/fotos/").concat(name).concat(".").concat(type);
    var imageRef = storageRef.child(path);
    await imageRef.putString(file, 'data_url').then(async function(snapshot) {}).catch(function(error) {
        return error;
    })
    var urlImagem = await imageRef.getDownloadURL()
    return {
        url: urlImagem,
    };
}

database.uploadImagemInspiracao = async(file, type, id, name) => {
    var storageRef = firebase.storage().ref();
    var path = environment.concat("/inspiracoes/").concat(id).concat("/banners/").concat(name).concat(".").concat(type);
    var imageRef = storageRef.child(path);
    await imageRef.putString(file, "data_url").then(async function(snapshot) {}).catch(function(error) {
        return error;
    })
    var urlImagem = await imageRef.getDownloadURL()
    return {
        url: urlImagem,
    };
}

database.uploadImagemCategoria = async(file, type, id, name) => {
    var storageRef = firebase.storage().ref();
    var path = environment.concat("/categorias/").concat(id).concat("/fotos/").concat(name).concat(".").concat(type);
    var imageRef = storageRef.child(path);
    await imageRef.putString(file, "data_url").then(async function(snapshot) {}).catch(function(error) {
        return error;
    })
    var urlImagem = await imageRef.getDownloadURL()
    return {
        url: urlImagem,
    };
}

database.uploadImagemTema = async(file, type, id, name) => {
    var storageRef = firebase.storage().ref();
    var path = environment.concat("/temas/").concat(id).concat("/fotos/").concat(name).concat(".").concat(type);
    var imageRef = storageRef.child(path);
    await imageRef.putString(file, "data_url").then(async function(snapshot) {}).catch(function(error) {
        return error;
    })
    var urlImagem = await imageRef.getDownloadURL()
    return {
        url: urlImagem,
    };
}

export default database
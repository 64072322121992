import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)


export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
      usuario: null,
      categorias: null,
  },
  mutations: {
    setUsuario(state, payload) {
        state.usuario = payload
    },
    setCategorias(state, payload) {
        state.categorias = payload
    }
  },
  getters: {
      getUsuario: state => state.usuario,
      getCategorias: state => state.categorias,
      getCategoriaById: (state) => id => {
          return state.categorias.find(categoria => categoria.id === id)
      },
      getUFs: state => [
          "AC",
          "AL",
          "AP",
          "AM",
          "BA",
          "CE",
          "DF",
          "ES",
          "GO",
          "MA",
          "MT",
          "MS",
          "MG",
          "PA",
          "PB",
          "PR",
          "PE",
          "PI",
          "RJ",
          "RN",
          "RS",
          "RO",
          "RR",
          "SC",
          "SP",
          "SE",
          "TO",]
  },
  actions: {},
  modules: {}
});
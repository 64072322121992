import Vue from "vue";
import VueRouter from "vue-router";
import store from '../store';

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    meta: {
      isRedirect: true
    }
  },
  {
    path: "/sistema",
    meta: {
        isRedirect: true
    }
  },
  {
    path: "/sistema/login",
    name: "login",
    component: () =>
        import ('../views/sistema/Login.vue')
  },

  {
    path: "/sistema/visao-geral",
    name: "dashboard",
    component: () =>
        import ('../views/sistema/Dashboard.vue'),
    meta: {
        layout: "dashboard",
        requiresAuth: true
    }
  },

  {
    path: "/sistema/usuarios/listagem",
    name: "dashboard-usuarios",
    component: () =>
        import ('../views/sistema/usuarios/Listagem.vue'),
    meta: {
        layout: "dashboard",
        requiresAuth: true
    }
  },

  {
    path: "/sistema/fornecedores/listagem",
    name: "dashboard-fornecedores",
    component: () =>
        import ('../views/sistema/fornecedores/Listagem.vue'),
    meta: {
        layout: "dashboard",
        requiresAuth: true
    }
  },

  {
    path: "/sistema/fornecedores/fornecedor/:id",
    name: "dashboard-fornecedor",
    component: () =>
        import ('../views/sistema/fornecedores/Edit.vue'),
    meta: {
        layout: "dashboard",
        requiresAuth: true
    }
  },

  {
    path: "/sistema/fornecedores/novo",
    name: "dashboard-fornecedores-novo",
    component: () =>
        import ('../views/sistema/fornecedores/Novo.vue'),
    meta: {
        layout: "dashboard",
        requiresAuth: true
    }
  },

  {
    path: "/sistema/fornecedores/pendentes",
    name: "dashboard-fornecedores-pendentes",
    component: () =>
        import ('../views/sistema/fornecedores/Pendentes.vue'),
    meta: {
        layout: "dashboard",
        requiresAuth: true
    }
  },

  {
    path: "/sistema/fornecedores/reprovados",
    name: "dashboard-fornecedores-reprovados",
    component: () =>
        import ('../views/sistema/fornecedores/Reprovados.vue'),
    meta: {
        layout: "dashboard",
        requiresAuth: true
    }
  },

  {
    path: "/sistema/fornecedores/avaliacoes",
    name: "dashboard-fornecedores-avaliacoes",
    component: () =>
        import ('../views/sistema/fornecedores/Avaliacoes.vue'),
    meta: {
        layout: "dashboard",
        requiresAuth: true
    }
  },

  {
    path: "/sistema/inspiracoes/listagem",
    name: "dashboard-inspiracoes",
    component: () =>
        import ('../views/sistema/inspiracoes/Listagem.vue'),
    meta: {
        layout: "dashboard",
        requiresAuth: true
    }
  },

  {
    path: "/sistema/inspiracoes/inspiracao/:id",
    name: "dashboard-inspiracao",
    component: () =>
        import ('../views/sistema/inspiracoes/Edit.vue'),
    meta: {
        layout: "dashboard",
        requiresAuth: true
    }
  },

  {
    path: "/sistema/inspiracoes/novo",
    name: "dashboard-inspiracoes-novo",
    component: () =>
        import ('../views/sistema/inspiracoes/Novo.vue'),
    meta: {
        layout: "dashboard",
        requiresAuth: true
    }
  },

  {
    path: "/sistema/temas/listagem",
    name: "dashboard-temas-listagem",
    component: () =>
        import ('../views/sistema/temas/Listagem.vue'),
    meta: {
        layout: "dashboard",
        requiresAuth: true
    }
  },{
    path: "/sistema/temas/novo",
    name: "dashboard-temas-novo",
    component: () =>
        import ('../views/sistema/temas/Novo.vue'),
    meta: {
        layout: "dashboard",
        requiresAuth: true
    }
  },

  {
    path: "/sistema/temas/novo",
    name: "dashboard-temas-novo",
    component: () =>
        import ('../views/sistema/temas/Novo.vue'),
    meta: {
        layout: "dashboard",
        requiresAuth: true
    }
  },

  {
    path: "/sistema/temas/tema/:id",
    name: "dashboard-tema",
    component: () =>
        import ('../views/sistema/temas/Edit.vue'),
    meta: {
        layout: "dashboard",
        requiresAuth: true
    }
  },

  {
    path: "/sistema/convites/novo",
    name: "dashboard-convites-novo",
    component: () =>
        import ('../views/sistema/convites/Novo.vue'),
    meta: {
        layout: "dashboard",
        requiresAuth: true
    }
  },

  {
    path: "/sistema/convites/convite/:id",
    name: "dashboard-convite",
    component: () =>
        import ('../views/sistema/convites/Edit.vue'),
    meta: {
        layout: "dashboard",
        requiresAuth: true
    }
  },

  {
    path: "/sistema/convites/listagem",
    name: "dashboard-convites-listagem",
    component: () =>
        import ('../views/sistema/convites/Listagem.vue'),
    meta: {
        layout: "dashboard",
        requiresAuth: true
    }
  },

  {
    path: "/sistema/categorias/listagem",
    name: "dashboard-categorias",
    component: () =>
        import ('../views/sistema/categorias/Listagem.vue'),
    meta: {
        layout: "dashboard",
        requiresAuth: true
    }
  },
  {
    path: "/sistema/categorias/novo",
    name: "dashboard-categorias-novo",
    component: () =>
        import ('../views/sistema/categorias/Novo.vue'),
    meta: {
        layout: "dashboard",
        requiresAuth: true
    }
  },

  {
    path: "/sistema/categorias/categoria/:id",
    name: "dashboard-categoria",
    component: () =>
        import ('../views/sistema/categorias/Edit.vue'),
    meta: {
        layout: "dashboard",
        requiresAuth: true
    }
  },

  {
    path: "/sistema/notificar",
    name: "dashboard-notificar",
    component: () =>
        import ('../views/sistema/notificacao/Notificar.vue'),
    meta: {
        layout: "dashboard",
        requiresAuth: true
    }
  },

  {
    path: '*',
    component: Vue.component("page-not-found", {
        template: "",
        created: function() {
            window.location.href = "/404/";
        }
    })
  } 

];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  linkActiveClass: 'active',
  linkExactActiveClass: 'exact-active',
  scrollBehavior() {
      return { x: 0, y: 0 };
  },
  routes
});

router.beforeEach((to, from, next) => {

  const usuario = store.state.usuario;

  const isRedirect = to.matched.some(record => record.meta.isRedirect);
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  console.log(usuario);
  console.log(isRedirect);
  console.log(requiresAuth);
  if (isRedirect) {
    if (usuario == null) {
        next("/sistema/login/");
    } else {
        next("/sistema/visao-geral/");
    }
  } else {
    if (requiresAuth) {
      if (usuario == null) {
        next("/sistema/login/");
      } else {
          next();
      }
    } else {
      next();
    }
  }
})

export default router;
